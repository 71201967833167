
import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';


export default function SideNav(props) {




return(

<Sidebar collapsedWidth="0px" collapsed={props.isSideNavCollapsed}>
  <Menu>
    <MenuItem component={<Link to="/" />} onClick={()=>props.toggleSideNav()}> Find apps </MenuItem>
    <MenuItem component={<Link to="/profile" />} onClick={()=>props.toggleSideNav()}> Profile </MenuItem>
    {props.user && props.user.isAdmin && 
    <SubMenu label="Admin">
      <MenuItem component={<Link to="/admin/settings" />} onClick={()=>props.toggleSideNav()}> Org settings </MenuItem>
      <MenuItem component={<Link to="/admin/utilization" />} onClick={()=>props.toggleSideNav()}> Utilization </MenuItem>
      <MenuItem component={<Link to="/admin/library" />} onClick={()=>props.toggleSideNav()}> Content library </MenuItem>

    </SubMenu>}
    {props.user && props.user.appOwner && 
    <SubMenu label="App Owner">
      <MenuItem component={<Link to="/owner/users" />} onClick={()=>props.toggleSideNav()}> Users </MenuItem>
      <MenuItem component={<Link to="/owner/profile" />} onClick={()=>props.toggleSideNav()}> Profile </MenuItem>
      <MenuItem component={<Link to="/owner/performance" />} onClick={()=>props.toggleSideNav()}> Performance </MenuItem>

    </SubMenu>}
  </Menu>
</Sidebar>

)
}